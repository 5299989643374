import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { API_URL } from '../api-config';

@Injectable()
export class ApiService {
  protected url = inject(API_URL);
  protected client = inject(HttpClient);

  protected get headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Request-Origin': 'web',
    };
  }

  protected get blobHeaders() {
    return {
      Accept: 'application/pdf',
      'Content-Type': 'application/pdf',
      'X-Request-Origin': 'web',
    };
  }

  get<T>(path: string, params: Record<string, string | number> = {}): Observable<T> {
    return this.client.get<T>(`${this.url}${path}`, {
      params,
      headers: this.headers,
    });
  }

  getBlob(path: string) {
    return this.client.get(`${this.url}${path}`, {
      responseType: 'blob',
      headers: this.blobHeaders,
    });
  }

  post<T>(path: string, body: Record<string, unknown> = {}): Observable<T> {
    return this.client.post<T>(`${this.url}${path}`, JSON.stringify(body), {
      headers: this.headers,
    });
  }

  put<T>(path: string, body: Record<string, unknown>): Observable<T> {
    return this.client.put<T>(`${this.url}${path}`, JSON.stringify(body), {
      headers: this.headers,
    });
  }

  delete<T>(path: string): Observable<T> {
    return this.client.delete<T>(`${this.url}${path}`, {
      headers: this.headers,
    });
  }
}
